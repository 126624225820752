<template>
  <div>
    <Header />

    <section class="page-content page-anim2">
      <div class="container pb-3">
        <transition enter-active-class="animated fadeIn">
          <div
            v-show="latestTemplates.length > 0"
            class="
              news-title-controls
              section-title
              mt-2-desktop mt-1-mobile mt-1-tablet
            "
          >
            <h1 class="title text-blue page-anim2">
              {{ t("Novidades") }}
            </h1>
            <div class="controls">
              <font-awesome-icon
                class="news-prev"
                :icon="['fas', 'chevron-left']"
              />
              <font-awesome-icon
                class="news-next"
                :icon="['fas', 'chevron-right']"
              />
            </div>
          </div>
        </transition>

        <transition enter-active-class="animated fadeInLeft">
          <div
            v-show="latestTemplates.length > 0"
            class="mt-2-desktop mt-1-mobile mt-1-tablet"
          >
            <div id="latestTemplatesSlider" class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  v-for="template in latestTemplates"
                  :key="template.id"
                  class="swiper-slide prod pointer"
                >
                  <div
                    @click="$router.push(`/template/${template.id}`)"
                    class="category-card"
                  >
                    <div class="img-holder">
                      <img class="bg-img" :src="template.coverImage" />
                    </div>
                    <p class="card-title text-blue">{{ template.title }}</p>
                  </div>
                </div>
                <div
                  @click="$router.push('/novidades')"
                  class="swiper-slide prod last pointer"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" />
                  <p>{{ t("Ver todas as novidades") }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <h1 class="title text-brown my-1 section-title">
          {{ t("Escolha uma categoria") }}
        </h1>
        <div class="cards-home-holder mt-2-desktop mt-1-mobile mt-1-tablet">
          <div
            @click="$router.push(`/templates/${cat.id}`)"
            v-for="cat in categories"
            :key="cat.id"
            class="category-card"
          >
            <div class="img-holder">
              <img class="bg-img" :src="cat.image" loading="lazy" />
            </div>
            <p class="card-title text-brown">{{ cat.title }}</p>

            <!-- <p class="description card-desc text-blue">
              {{ cat.description }}
            </p> -->
          </div>
        </div>

        <div v-show="loading" class="flex-center mt-2">
          <div class="loading-spinner blue"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
export default {
  name: "Home",
  components: {
    Header,
  },
  data() {
    return {
      loading: true,
      categories: [],
      page: 0,
      take: 50,
      latestTemplates: [],
    };
  },
  created() {
    http
      .get(`category?take=-1`, authentication.getHeaders())
      .then((response) => {
        this.categories = response.data.list;
      })
      .catch((err) => {
        if (err.response.status === 401) this.$router.push("login");
      })
      .finally(() => {
        this.loading = false;
      });

    if (this.$route.query.newUser) {
      gtag("event", "conversion", {
        send_to: "AW-605007956/v0RdCP6KzdwBENTgvqAC",
      });
      console.log("GTM conversion event fired");
    }

    if (localStorage.paymentFailed) {
      this.$swal.fire({
        type: "warning",
        title: this.$translate.locale["Pagamento Pendente"],
        text: this.$translate.locale[
          "Algo deu errado com o seu pagamento. Por favor, tente novamente indo em: 'menu - meus dados - plano' para liberar as funcionalidades do app"
        ],
      });
      localStorage.removeItem("paymentFailed");
    }

    this.getLatestTemplates();
  },
  methods: {
    getLatestTemplates() {
      http
        .get(`template?take=8`, authentication.getHeaders())
        .then((response) => {
          this.latestTemplates = response.data.list;
          this.initSlider();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initSlider() {
      setTimeout(() => {
        new Swiper("#latestTemplatesSlider", {
          freeMode: true,
          slidesPerView: "auto",
          spaceBetween: 30,
          navigation: {
            nextEl: ".news-next",
            prevEl: ".news-prev",
          },
        });
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.news-title-controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .controls {
    position: relative;
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 1.5em;
      cursor: pointer;
      color: var(--blue);
      &.swiper-button-disabled {
        opacity: 0.3;
      }
    }
  }
}

.title.category {
  margin-top: 10px;
}

.prod {
  position: relative;
  width: 170px !important;
  overflow: hidden;
  transition: margin 0.2s ease;
}

.prod img {
  position: relative;
  width: 100%;
}

.prod.last {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffebdb;
  border-radius: 1em;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  margin-right: 10px;
}

.prod.last svg {
  font-size: 2.2em;
  color: var(--blue);
}

.prod.last p {
  margin: 12px 10px 0 12px;
  font-size: 1.2em;
  text-align: center;
  color: var(--blue);
}
</style>
